import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "../../App.scss";
import style from "./assets/scss/accueil.module.scss";
import base_doc from "./assets/images/doc-jra/base-documentaire.jpg";
import offres_emploi from "./assets/images/doc-jra/offres-d-emploi.png";
import opo_img from "./assets/images/doc-jra/opo_img.png";
import handicap from "./assets/images/doc-jra/accompagnement-handicap.jpg";
import charte_ethique from "./assets/images/doc-jra/charte-ethique.jpg";
import livret_accueil from "./assets/images/doc-jra/livret-d-accueil.jpg";

import { Service_Api } from "../../services/Api";
import { useAuth } from "../../services/auth/useAuth";
import {
  Post,
  postCategoriesNews,
  postCategoriesNewEmployee,
} from "../../services/object/Post";
import { App } from "../../services/object/App";
import { PostStats, postStatsUid } from "../../services/object/PostStats";

import { jsDateToLocalFr } from "../../services/tools/translateDate";
import { truncateText } from "../../services/tools/truncateText";
import { formatWithLineBreaks } from "../../services/tools/formatWithLineBreak"

import { Loader } from "../../components/loader/Loader";

import PostModal from "./PostModal";

import noPhotoPost from "./assets/images/noPhotoPost.png";
import noPhotoNewEmployee from "./assets/images/noPhotoNewEmployee.jpg";
import youtubeLogo from "./assets/images/youtubeLogo.png";

import { useMediaQuery } from '@mui/material';
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton"
import CircularProgress from '@mui/material/CircularProgress';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import Carousel from "react-material-ui-carousel";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExtensionIcon from "@mui/icons-material/Extension";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { newsConfig } from "../../services/object/Law";
import { useLaw } from "../../services/auth/useLaw";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import BuildIcon from '@mui/icons-material/Build';
import AddIcon from "@mui/icons-material/Add";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import SettingsIcon from '@mui/icons-material/Settings';
import ListIcon from '@mui/icons-material/List';

type PostOrPostStats = Post | PostStats;

function Accueil() {

  const Api = Service_Api();
  const { user } = useAuth();
  const { law } = useLaw();

  const [loader, setLoader] = useState<boolean>(true);
  const [TOPost, setTOPost] = useState<Post[]>([]);
  const [Opo, setOpo] = useState<{
                                      text: string;
                                      alt: string;
                                      url: string;
                                      src: string;
                                  }>();
  const [initialLoader, setInitialLoader] = useState(true);
  const [loadingPosts, setLoadingPosts] = useState<string[]>([]);
  const [postStats, setPostStats] = useState<PostStats>();
  const [TOUserApp, setTOUserApp] = useState<App[]>([]);

  const [likedPosts, setLikedPosts] = useState<string[]>([]);

  const [modalType, setModalType] = useState<string>("");
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [postModalData, setPostModalData] = useState<Post>({
    uid: "",
    title: "",
    subtitle: "",
    description: "",
    url: "",
    urlYoutube: "",
    photo: "",
    newEmployeeJob: "",
    newEmployeeSalepointUid: "",
    univers: {
      uid: "",
      name: ""
    },
    salepoint: {
      uid: "",
      name: ""
    },
    postCategoryId: 0,
    isImportantPost: false,
    likeNumber: 0,
    isDraft: false,
    isSuggestedPost: false,
    createdBy: null,
    date: "",
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
  });

  const isMobile = useMediaQuery('(max-width: 768px)');
  const [openTooltip, setOpenTooltip] = useState<string | null>(null);

  const handleTooltipOpen = (tooltipId: string) => {
    if (isMobile) {
      setOpenTooltip(openTooltip === tooltipId ? null : tooltipId);
    }
  };
  const handleTooltipClose = () => {
    setOpenTooltip(null);
  };

  useEffect(() => {
    setTimeout(() => setInitialLoader(false), 1000);
  }, []);
  useEffect(() => {
    document.title = "MyJra";

    const fetchData = async () => {
      await fetchInitialData();
      const response = await Api.get(
        "post/getLikedPosts",
        `userUid=${user.uid}`
      );
      if (response?.data) setLikedPosts(response?.data)
    };
    fetchData();
    setTimeout(function () {
      setLoader(false);
    }, 1000);
    getPoLink();
  }, []);
  const fetchInitialData = async () => {
    await Promise.all([fetchAllPosts(), fetchPostStats(), fetchAppsByUserUid()]);
  };

  // fetch data
  const fetchAllPosts = async () => {
    try {
      const response = await Api.get("post/getAllPostForHome");
      const posts = response?.data;
      const publishedPosts = posts.filter(
        (post: any) => post.isDraft === false
      );

      const newEmployeePosts = publishedPosts.filter(
        (post: any) => post.postCategoryId === postCategoriesNewEmployee
      );
      const otherPosts = publishedPosts.filter(
        (post: any) => post.postCategoryId !== postCategoriesNewEmployee
      );

      for (const post of newEmployeePosts) {
        setLoadingPosts((prev) => [...prev, post.uid]);
        setTOPost((prev) => [...prev, post]);

        await new Promise((resolve) => setTimeout(resolve, 500));

        setLoadingPosts((prev) => prev.filter((uid) => uid !== post.uid));
      }

      for (const post of otherPosts) {
        setLoadingPosts((prev) => [...prev, post.uid]);
        setTOPost((prev) => [...prev, post]);

        await new Promise((resolve) => setTimeout(resolve, 500));

        setLoadingPosts((prev) => prev.filter((uid) => uid !== post.uid));
      }

      console.log("Données récupérées avec succès", publishedPosts);
    } catch (error) {
      console.error("Erreur lors de la récupération des données", error);
    }
  };
  const fetchPostStats = async () => {
    try {
      const response = await Api.get("postStats/", "uid=" + postStatsUid);
      const postStats = response?.data;
      setPostStats(postStats);
      console.log("Données récupérées avec succès", postStats);
    } catch (error) {
      console.error("Erreur lors de la récupération des données", error);
    }
  };
  const fetchAppsByUserUid = async () => {
    try {
      const response = await Api.get(
        "app/getAppsByUserUid",
        "userUid=" + user.uid
      );
      const T_userApps = response?.data;

      setTOUserApp(T_userApps);
      console.log("Données récupérées avec succès", T_userApps);
    } catch (error) {
      console.error("Erreur lors de la récupération des données", error);
    }
  };
  const extractYouTubeId = (url: string): string => {
    const regExp =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|shorts\/|watch\?v=|watch\?.+&v=)|youtu\.be\/)([^#&?\/\n]+)(?:\S+)?/;
    const match = url.match(regExp);
    return match ? match[1] : "";
  };
  const getYouTubeThumbnailUrl = (url: string): string => {
    const videoId = extractYouTubeId(url);
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };

  // render data
  const renderPersonnalApps = () => {
    const elements: JSX.Element[] = [];
    const limitedApps = TOUserApp.slice(0, 5);

    for (const [index, app] of limitedApps.entries()) {
      elements.push(
        <li key={index} className="hoverShadow">
          <a href={app.url} target="_blank">
            {app.photo ? (
              <img
                src={app.photo}
                alt={app.name}
              />
            ) : (
              <ExtensionIcon />
            )}
            <p>{app.name}</p>
          </a>
        </li>
      );
    }

    return <ul className="autoScrollHorizontal">{elements}</ul>;
  };

  const getPoLink = async () => {
    let response = await Api.del("post/getOpo");
    if(response?.success){
      response.data.src = opo_img;
      response.data.text = "Suivi des OPO";
      setOpo(response.data)
    }
  }

  const renderDefaultApps = () => {
    let defaultApps = [
      {
        text: "Base documentaire",
        alt: "Base documentaire",
        href: "https://sites.google.com/jeanrouyerautomobiles.fr/base-documentaire/accueil",
        src: base_doc,
      },
      {
        text: Opo?.text ?? "Offres d'emploi",
        alt: Opo?.text ?? "Offres d'emploi",
        href: Opo?.url ?? "https://recrutement.jeanrouyerautomobiles.fr/fr",
        src: Opo?.src ?? offres_emploi,
      },
      {
        text: "Accompagnement handicap",
        alt: "Accompagnement handicap",
        href: "https://drive.google.com/file/d/1ygsQUxqYF6lnxt-6xGZYUYqAxnwvAqID/view?usp=drive_link",
        src: handicap,
      },
      {
        text: "Charte éthique",
        alt: "Charte éthique",
        href: "https://drive.google.com/file/d/1fOsx-t-2ns-wa-bFTxVNP38EcrB5YJip/view?usp=drive_link",
        src: charte_ethique,
      },
      {
        text: "Livret d'accueil",
        alt: "Livret d'accueil",
        href: "https://drive.google.com/file/d/1f5Q8GIrchNdvG8YShLP_EeYrUdFtf8ol/view",
        src: livret_accueil,
      },
    ];
    

    return (
      <ul className="autoScrollHorizontal">
        {defaultApps.map((app, index) => (
          <li key={index} className="hoverShadow">
            <a href={app.href} target="_blank">
              <img src={app.src} alt={app.alt} />
              <p className={style.backgroundBlur}>{app.text}</p>
            </a>
          </li>
        ))}
      </ul>
    );
  };
  const renderPostForCarousel = (post: Post) => {
    let isLiked = false;
    if (likedPosts) {
      isLiked = likedPosts.includes(post.uid);
    }

    return (
      <div className={style.post}>
        <div className={style.postContent} key={post.uid}>
          <div className={style.postBody}>
            <div className={style.imageBox}>
              <div className={style.likeNumberBox}>
                <div
                  className={`${style.likeButton} ${isLiked ? style.isActive : ""
                    }`}
                  onClick={() => onLikeClick(post.uid)}
                >
                  <ThumbUpIcon
                    className={`${style.isLiked} ${style.bouncy}`}
                  />
                  <ThumbUpOffAltIcon
                    className={`${style.notLiked} ${style.bouncy}`}
                  />
                  <span className={style.likeOverlay}></span>
                </div>
                <span className={style.likeNumber}>{post.likeNumber}</span>
              </div>
              {post.urlYoutube ? (
                <div className={style.youtubeBox}>
                  <img src={youtubeLogo} className={style.youtubeLogo} />
                  <img
                    src={getYouTubeThumbnailUrl(post.urlYoutube)}
                    alt="YouTube video thumbnail"
                  />
                </div>
              ) : (
                <img src={post.photo ? post.photo : noPhotoPost} alt="actualité" />
              )}
            </div>
            <div className={`${style.postText} ${style.backgroundBlur}`}>
              <div className={style.postContent}>
                <div>
                  <h3>{truncateText(post.title, 100)}</h3>
                  {post.subtitle && <h4>{truncateText(post.subtitle, 100)}</h4>}
                  {post.description && (
                    <Box
                      className={style.content}
                      sx={{ whiteSpace: "pre-wrap" }}
                    >
                      {truncateText(post.description, 100)}
                    </Box>
                  )}
                </div>
                <div>
                  <p className={style.date}>
                    {post.date &&
                      jsDateToLocalFr(new Date(post.date))}
                  </p>
                  <p
                    className={`button smallButton ${style.button}`}
                    onClick={() => onOpenPostModalClick(post.uid, "news")}
                  >
                    Voir l'actu
                    <ExitToAppIcon />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  };
  const renderPostsCarousel = () => {
    const importantPosts = TOPost.filter(
      (post) =>
        post.postCategoryId === postCategoriesNews && post.isImportantPost
    );

    if (importantPosts.length > 1) {
      return (
        <Carousel
          autoPlay={true}
          interval={8000}
          stopAutoPlayOnHover={true}
          animation="slide"
          duration={1500}
          className={style.carousel}
        >
          {importantPosts.map(renderPostForCarousel)}
        </Carousel>
      );
    } else if (importantPosts.length === 1) {
      const post = importantPosts[0]

      let isLiked = false;
      if (likedPosts) {
        isLiked = likedPosts.includes(post.uid);
      }

      return (
        <div className={`${style.post} ${style.singlePost}`}>
          <div className={style.postContent} key={post.uid}>
            <div className={style.postBody}>
              <div className={style.imageBox}>
                <div className={style.likeNumberBox}>
                  <div
                    className={`${style.likeButton} ${isLiked ? style.isActive : ""
                      }`}
                    onClick={() => onLikeClick(post.uid)}
                  >
                    <ThumbUpIcon
                      className={`${style.isLiked} ${style.bouncy}`}
                    />
                    <ThumbUpOffAltIcon
                      className={`${style.notLiked} ${style.bouncy}`}
                    />
                    <span className={style.likeOverlay}></span>
                  </div>
                  <span className={style.likeNumber}>{post.likeNumber}</span>
                </div>
                {post.urlYoutube ? (
                  <div className={style.youtubeBox}>
                    <img src={youtubeLogo} className={style.youtubeLogo} />
                    <img
                      src={getYouTubeThumbnailUrl(post.urlYoutube)}
                      alt="YouTube video thumbnail"
                    />
                  </div>
                ) : (
                  <img src={post.photo ? post.photo : noPhotoPost} alt="actualité" />
                )}
              </div>
              <div className={`${style.postText} ${style.backgroundBlur}`}>
                <div className={style.postContent}>
                  <div>
                    <h3>{truncateText(post.title, 100)}</h3>
                    {post.subtitle && <h4>{truncateText(post.subtitle, 100)}</h4>}
                    {post.description && (
                      <Box
                        className={style.content}
                        sx={{ whiteSpace: "pre-wrap" }}
                      >
                        {truncateText(post.description, 100)}
                      </Box>
                    )}
                  </div>
                  <div>
                    <p className={style.date}>
                      {post.date &&
                        jsDateToLocalFr(new Date(post.date))}
                    </p>
                    <p
                      className={`button smallButton ${style.button}`}
                      onClick={() => onOpenPostModalClick(post.uid, "news")}
                    >
                      Voir l'actu
                      <ExitToAppIcon />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )

    } else {
      return (
        <div className={style.post}>
          <div className={style.postContent} key="1">
            <div className={style.postBody}>
              <img src={noPhotoPost} alt="actualité" />
              <div className={`${style.postText} ${style.backgroundBlur}`}>
                <div className={style.description}>
                  <p className="disabledText">Aucune actualité</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
  const renderPostForList = (post: Post, index: number) => {
    let isLiked = false;
    if (likedPosts) {
      isLiked = likedPosts.includes(post.uid);
    }
    const isLoading = loadingPosts.includes(post.uid);

    return (
      <>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <div className={style.post}>
            <div className={style.postContent}>
              <div className={style.header}>
                <div className={style.tagsBox}>
                  <span className={style.date}>
                    {post.date && jsDateToLocalFr(new Date(post.date))}
                  </span>
                </div>
              </div>
              <div className={style.postBody}>
                <div className={style.imageBox}>
                  <div className={style.likeNumberBox}>
                    <div
                      className={`${style.likeButton} ${isLiked ? style.isActive : ""
                        }`}
                      onClick={() => onLikeClick(post.uid)}
                    >
                      <ThumbUpIcon
                        className={`${style.isLiked} ${style.bouncy}`}
                      />
                      <ThumbUpOffAltIcon
                        className={`${style.notLiked} ${style.bouncy}`}
                      />
                      <span className={style.likeOverlay}></span>
                    </div>
                    <span className={style.likeNumber}>{post.likeNumber}</span>
                  </div>

                  {post.urlYoutube ? (
                    <div className={style.youtubeBox}>
                      <img src={youtubeLogo} className={style.youtubeLogo} />
                      <img
                        src={getYouTubeThumbnailUrl(post.urlYoutube)}
                        alt="YouTube video thumbnail"
                      />
                    </div>
                  ) : (
                    <img
                      src={post.photo ? post.photo : noPhotoPost}
                      alt="actualité"
                    />
                  )}
                </div>
                <div className={`${style.postText} ${style.backgroundBlur}`}>
                  <h3>{post.title}</h3>
                  {post.subtitle && <h4>{post.subtitle}</h4>}
                  {post.description && (
                    <p className={style.content}>
                      {formatWithLineBreaks(truncateText(post.description, 270))}
                    </p>
                  )}
                  <p
                    className={`button smallButton ${style.button}`}
                    onClick={() => onOpenPostModalClick(post.uid, "news")}
                  >
                    Voir l'actu
                    <ExitToAppIcon />
                  </p>
                </div>
              </div>
            </div>
            <hr />
          </div>
        )}
      </>
    );
  }
  const renderStatBlock = (title: string, value: string, explanation: string | undefined, tooltipKey: string) => (
    <div className={style.block}>
      <p className={style.number}>{value}</p>
      <p className={style.title}>
        {title}
        {explanation ? (
          isMobile ? (
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  title={explanation}
                  open={openTooltip === tooltipKey}
                  onClose={handleTooltipClose}
                  disableHoverListener={isMobile}
                >
                  <IconButton onClick={() => handleTooltipOpen(tooltipKey)}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </ClickAwayListener>
          ) : (
            <Tooltip title={explanation}>
              <IconButton>
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          )
        ) : null}
      </p>
    </div>
  );
  const renderPostStatsForList = () => {
    if (postStats?.titleOne || postStats?.titleTwo || postStats?.titleThree || postStats?.titleFour) {
      return (
        <div id={style.stats}>
          <p className={style.statsTitle}>
            Les chiffres du {postStats.date && jsDateToLocalFr(new Date(postStats.date))}
          </p>
          <div className={style.statsBox}>
            {postStats.titleOne && renderStatBlock(postStats.titleOne, postStats.valueOne, postStats.explanationOne, 'tooltipOne')}
            {postStats.titleTwo && renderStatBlock(postStats.titleTwo, postStats.valueTwo, postStats.explanationTwo, 'tooltipTwo')}
            {postStats.titleThree && renderStatBlock(postStats.titleThree, postStats.valueThree, postStats.explanationThree, 'tooltipThree')}
            {postStats.titleFour && renderStatBlock(postStats.titleFour, postStats.valueFour, postStats.explanationFour, 'tooltipFour')}
          </div>
        </div>
      );
    }
  };
  const renderPostsList = () => {
    const T_newsPosts = TOPost.filter(
      (post) =>
        post.postCategoryId === postCategoriesNews &&
        post.isImportantPost === false
    );

    if (T_newsPosts.length > 0) {
      const allPostsAndStats: PostOrPostStats[] = [...T_newsPosts];

      if (postStats) {
        allPostsAndStats.push(postStats);
      }
      allPostsAndStats.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

      return (
        <div className={style.postListContent}>
          <div className={style.list}>
            {allPostsAndStats.map((item, index) => {
              if ('titleOne' in item) {
                return renderPostStatsForList();
              } else {
                return renderPostForList(item as Post, index);
              }
            })}
          </div>
          <Link to="/toutes-actus" className={`button ${style.button}`}>
            Toutes les actualités
            <ExitToAppIcon />
          </Link>
        </div>
      );
    } else {
      return <CircularProgress className={style.styledCircularProgress} />
    }
  };
  const renderNewEmployeeForList = (post: Post) => {
    const isLoading = loadingPosts.includes(post.uid);

    return (
      <div
        className={`${style.newEmployee} hoverShadow`}
        onClick={() => onOpenPostModalClick(post.uid, "newEmployee")}
      >
        {isLoading ? (
          <CircularProgress className={style.styledCircularProgress} />
        ) : (
          <img
            src={post.photo ? post.photo : noPhotoNewEmployee}
            alt="nouveau collaborateur"
          />
        )}
        <p className={style.backgroundBlur}>
          {post.title} {post.subtitle}
        </p>
      </div>
    );
  };
  const renderNewEmployeeList = () => {
    const newEmployeePosts = TOPost
      .filter((post) => post.postCategoryId === postCategoriesNewEmployee)
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    return (
      <>
        {initialLoader ? (
          <CircularProgress className={style.styledCircularProgress} />
        ) : newEmployeePosts.length ? (
          <div className={`${style.list} autoScrollHorizontal`}>
            {newEmployeePosts.map((post) => renderNewEmployeeForList(post))}
          </div>
        ) : (
          <p className="disabledText">Aucun nouveau collaborateur</p>
        )}
      </>
    )
  };

  // actions 
  const onOpenPostModalClick = async (postUid: string, modalType: string) => {
    await fetchPostModalDetails(postUid);
    setIsPostModalOpen(true);
    setModalType(modalType);
  };
  const onClosePostModal = async () => {
    setIsPostModalOpen(false);
  };
  const onLikeClick = async (uid: string) => {
    try {
      let updatedPosts = [...TOPost];
      const postIndex = updatedPosts.findIndex((post) => post.uid === uid);

      if (likedPosts.includes(uid)) {
        await Api.del("post/changeAssoLike", {
          userUid: user.uid,
          postUid: uid,
        });
        setLikedPosts((prevLikedPosts) =>
          prevLikedPosts.filter((postUid) => postUid !== uid)
        );

        if (postIndex !== -1) {
          updatedPosts[postIndex].likeNumber -= 1;
        }
      } else {
        await Api.post("post/changeAssoLike", {
          userUid: user.uid,
          postUid: uid,
        });
        setLikedPosts((prevLikedPosts) => [...prevLikedPosts, uid]);

        if (postIndex !== -1) {
          updatedPosts[postIndex].likeNumber += 1;
        }
      }

      setTOPost(updatedPosts);

      if (postModalData.uid === uid) {
        setPostModalData((prevModalData) => ({
          ...prevModalData,
          likeNumber: updatedPosts[postIndex].likeNumber,
        }));
      }
    } catch (error) {
      console.error("Erreur lors du traitement du like", error);
    }
  };
  const onSuggestPostClick = () => {
    window.location.href = "/toutes-actus#open-modal";
  };

  // Process datas after actions
  const fetchPostModalDetails = async (postUid: string) => {
    let response;

    setPostModalData({
      uid: "",
      title: "",
      subtitle: "",
      description: "",
      url: "",
      urlYoutube: "",
      photo: "",
      newEmployeeJob: "",
      newEmployeeSalepointUid: "",
      univers: {
        uid: "",
        name: ""
      },
      salepoint: {
        uid: "",
        name: ""
      },
      postCategoryId: 0,
      isImportantPost: false,
      likeNumber: 0,
      isDraft: false,
      isSuggestedPost: false,
      createdBy: null,
      date: "",
      createdAt: "",
      updatedAt: "",
      deletedAt: "",
    });

    try {
      response = await Api.get("post/", "uid=" + postUid);
      console.log("Post récupéré avec succès", response);
      setPostModalData(response?.data);
    } catch (error) {
      console.error("Erreur lors de la récupération du post", error);
    }
  };

  return loader ? (
    <Loader />
  ) : (
    <div id={style.homePage} className="background">
      <div className={style.content}>
        <div id={style.columnOne} className={style.column}>
          <div className={style.firstBlock}>
            <div id={style.postsCarousel} className={style.block}>
              {renderPostsCarousel()}
            </div>

            <div id={style.userApps} className={`${style.appsBlock} ${style.block}`}>
              <div className={style.header}>
                <h2>Mes outils</h2>
                <Tooltip title="Profil">
                  <Link to="/profile" className={`${style.button}`}>
                    <BuildIcon />
                  </Link>
                </Tooltip>
              </div>
              {renderPersonnalApps()}
            </div>
          </div>

          <div id={style.docJra} className={`${style.appsBlock} ${style.block} ${style.secondBlock}`}>
            <h2>
              Ma documentation JRA <OpenInNewIcon />
            </h2>
            {renderDefaultApps()}
          </div>

        </div>

        <div id={style.columnTwo} className={style.column}>
          <div id={style.postsList} className={`${style.block} ${style.firstBlock} autoScrollVertical ${style.autoScroll}`}>
            <div className={`${style.blockHeader}`}>
              <h2>
                <span>Les nouvelles</span>
                <span className={style.postCategory}>actualités</span>
              </h2>
              <div className={style.buttonBox}>
                <p className={`button roundButton ${style.button}`} onClick={() => onSuggestPostClick()}>
                  <Tooltip title="Suggérer une actualité">
                    <AddIcon />
                  </Tooltip>
                </p>
                {law && law.filter((law) => law.uid === newsConfig).length === 1 && (
                  <Link to="/accueilConfig" className={`button ${style.button} ${style.textButton}`}>
                    <span className={style.respoLaptop}>Gérer les actualités</span>
                    <Tooltip title="Gérer les actualités">
                      <SettingsIcon />
                    </Tooltip>
                  </Link>
                )}
                <Link to="/toutes-actus" className={`button ${style.button} ${style.textButton}`}>
                  <span className={style.respoLaptop}>Toutes les actualités</span>
                  <Tooltip title="Voir toutes les actualités">
                    <ListIcon />
                  </Tooltip>
                </Link>
              </div>
            </div>

            {initialLoader ? (
              <CircularProgress className={style.styledCircularProgress} />
            ) : TOPost.length ? (
              renderPostsList()
            ) : (
              <p className="disabledText">Aucune actualité</p>
            )}

          </div>

          <div id={style.newEmployeelist} className={`${style.block} ${style.secondBlock}`}>
            <h2>
              <span>Les nouveaux</span>
              <span className={style.postCategory}>collaborateurs</span>
            </h2>
            {renderNewEmployeeList()}
          </div>
        </div>
      </div>

      <PostModal
        isOpen={isPostModalOpen}
        onClose={onClosePostModal}
        post={postModalData}
        modalType={modalType}
        isLiked={likedPosts.includes(postModalData.uid)}
        likeNumber={postModalData.likeNumber}
        onLikeClick={() => onLikeClick(postModalData.uid)}
      />
    </div>
  );
}

export default Accueil;

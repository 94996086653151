import * as React from "react";
import FormPostOpo from "./FormPostOpo";
import style from "../styles/accueilConfig.module.scss";

interface ModalPostOpoProps {
  errorPostMessages: Record<string, string>;
  setFormPostOpo: React.Dispatch<React.SetStateAction<{
                                                        active: boolean;
                                                        dateEnd: string;
                                                        url: string;
                                                      } | undefined>>;
  formPostOpo: {
    active: boolean;
    dateEnd: string;
    url: string;
} | undefined;
formRefPostOpo: React.RefObject<HTMLFormElement>;
}

export default function ModalPostOpo({
  errorPostMessages,
  setFormPostOpo,
  formPostOpo,
  formRefPostOpo,
}: ModalPostOpoProps) {
  return [
    <>
      <form className={`${style.uiform} ${style.postForm}`} ref={formRefPostOpo}>
        <FormPostOpo
          onChange={(property, value)=>(setFormPostOpo((prevValue: any) => ({
                                                        ...prevValue,
                                                        [property]: value,
                                                      })))}
          formPost={formPostOpo}
          errorPostMessages={errorPostMessages}
        />
      </form>
    </>
  ];
}

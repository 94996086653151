import React, { useState, useEffect, ChangeEvent } from "react";

import style from "../styles/accueilConfig.module.scss";


import TextField from "@mui/material/TextField";
import { FormControlLabel, FormGroup, SelectChangeEvent, Switch } from "@mui/material";

import { DatePicker, LocalizationProvider, frFR } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';


interface FormProps {
  onChange: (property: string, value: string | boolean | null) => void;
  formPost: {
                active: boolean;
                dateEnd: string;
                url: string;
            } | undefined;
  errorPostMessages: Record<string, string>;
  isSuggestionForm?: boolean;
}

const FormPostOpo = ({
  onChange,
  formPost,
  errorPostMessages,
}: FormProps) => {

  const [localFormValues, setLocalFormValues] = useState({
                                                            active: formPost?.active ?? false,
                                                            dateEnd: formPost?.dateEnd ?? "",
                                                            url: formPost?.url ?? "",
                                                        });

  useEffect(() => {
    if (formPost) {
      setLocalFormValues({
        active: formPost?.active ?? false,
        dateEnd: formPost?.dateEnd ?? "",
        url: formPost?.url ?? "",
      });
    }
  }, [formPost]);

  const handleLocalChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setLocalFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    onChange(name, value);
  };
  const handleDatePickerChange = (newValue: dayjs.Dayjs | null) => {
    if (newValue && newValue.format('YYYY-MM-DD') !== 'Invalid Date') {
      onChange("dateEnd", newValue.format('YYYY-MM-DD'));
    }else{
      onChange("dateEnd", null);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onChange("active", checked);
    setLocalFormValues((prevValues) => ({
      ...prevValues,
      "active": checked,
    }));
  }
  return (
    <div className={`${style.column} ${style.content}`}>
      <div className={`${style.row} ${style.alt}`}>
        <div className={`${style.column} ${style.firstBox}`}>
          <FormGroup>
            <FormControlLabel control={<Switch checked={localFormValues.active}
                                                onChange={handleChange} />} 
                                                label="Activer la visibilité du suivi des OPO"
                                                labelPlacement="start" />
          </FormGroup>
          <TextField
            fullWidth
            label={"URL du suivi"}
            size="small"
            name="url"
            value={localFormValues.url}
            onChange={handleLocalChange}
            onBlur={handleBlur}
            required
            error={!!errorPostMessages.url}
            helperText={errorPostMessages.url}
            className={style.input}
          />
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="fr"
            localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
          >
            <DatePicker
              value={formPost?.dateEnd ? dayjs(formPost.dateEnd) : null}
              label="Date de fin de visibilité (facultatif)"
              onChange={handleDatePickerChange}
              className={`${style.date} ${style.input}`}
              slotProps={{ textField: { size: 'small' } }}
            />
          </LocalizationProvider>

        </div>
      </div>
    </div>
  );
};

export default FormPostOpo;
